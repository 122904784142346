<template>
  <div class="setting">
    <div class="title">通话面板：</div>
    <p class="paragraph">1. 拨号盘：除拨打按钮，拨号盘0/1/2/3/4/5/6/7/8/9/*/#/+已绑定到键盘对应的键</p>
    <p class="paragraph">2. 最近来电：仅显示最近十条，为临时提示记录，刷新将会重置数据。永久数据请查看--记录面板--</p>
	<p class="paragraph">3. 通话或来电或拨打：请注意这三个情况下请不要刷新或者关闭浏览器，会导致通话中断</p>
  </div>
</template>

<script>
	export default {}
</script>

<style lang="scss" scoped>
.setting{
  padding: 30px;
  line-height: 2;
  .title{
    font-size: 16px;
  }
  .paragraph{
    padding-left: 20px;
    font-size: 14px;
    color: rgb(82, 81, 81);
  }
}
</style>
